define("discourse/plugins/discourse-ratings-custom-version/discourse/components/modal/rate-error", ["exports", "@ember/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @model.isVisible}}
    <DModal @title={{"Error"}} @closeModal={{@closeModal}}>
      <div class="your-modal">
        <div class="message">
          <p>
            {{@model.errorMessage}}
          </p>
        </div>
        <div class="modal-footer">
          <DButton
            @label={{"modal.accept"}}
            class="btn-primary send-invite"
            @action={{@closeModal}}
          />
        </div>
      </div>
    </DModal>
  {{/if}}
  */
  {
    "id": "uVNPOz+Q",
    "block": "[[[41,[30,1,[\"isVisible\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@title\",\"@closeModal\"],[\"Error\",[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"your-modal\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"message\"],[12],[1,\"\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[30,1,[\"errorMessage\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n        \"],[8,[39,4],[[24,0,\"btn-primary send-invite\"]],[[\"@label\",\"@action\"],[\"modal.accept\",[30,2]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@model\",\"@closeModal\"],false,[\"if\",\"d-modal\",\"div\",\"p\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-ratings-custom-version/discourse/components/modal/rate-error.hbs",
    "isStrictMode": false
  });
  class RateErrorModal extends _component.default {
    cancel() {
      this.args.closeModal();
    }
    static #_ = (() => dt7948.n(this.prototype, "cancel", [_object.action]))();
  }
  _exports.default = RateErrorModal;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RateErrorModal);
});