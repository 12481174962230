define("discourse/plugins/discourse-ratings-custom-version/discourse/templates/connectors/user-main-nav/user-feeback", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DNavigationItem @route="reviews" class="user-nav__summary">
    {{d-icon "user"}}
    <span class="rating-review-tab">Seller Reviews</span>
  </DNavigationItem>
  <DNavigationItem @route="buyers" class="user-nav__summary">
    {{d-icon "user"}}
    <span class="rating-review-tab">Buyer Reviews</span>
  </DNavigationItem>
  */
  {
    "id": "3mgHQGwB",
    "block": "[[[8,[39,0],[[24,0,\"user-nav__summary\"]],[[\"@route\"],[\"reviews\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[\"user\"],null]],[1,\"\\n  \"],[10,1],[14,0,\"rating-review-tab\"],[12],[1,\"Seller Reviews\"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[8,[39,0],[[24,0,\"user-nav__summary\"]],[[\"@route\"],[\"buyers\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[\"user\"],null]],[1,\"\\n  \"],[10,1],[14,0,\"rating-review-tab\"],[12],[1,\"Buyer Reviews\"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"d-navigation-item\",\"d-icon\",\"span\"]]",
    "moduleName": "discourse/plugins/discourse-ratings-custom-version/discourse/templates/connectors/user-main-nav/user-feeback.hbs",
    "isStrictMode": false
  });
});